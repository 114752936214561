import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

//Image
import ImageComponent from '@/components/widgets/image';

//materials
import { Box, Typography } from '@mui/material';

//components
import TitleSection from '@/components/shared/titleSection';
import StepFour from './partials/stepFour';
import StepOne from './partials/stepOne';
import StepThree from './partials/stepThree';
import StepTwo from './partials/stepTwo';

//styles
import useStyles from './style';

///utils
import * as eventActions from '@/utils/analytics';
import { scroller } from 'react-scroll';
import { errorMessages, testName, textValues } from './constants';
import { Step, getEducationScore, getTargetIntakes, textVal } from './util';

export default function LiveCounsellingSection({
	cardList,
	cityLabel,
	educationBacklogsLabel,
	educationScoreLabel,
	emailLabel,
	firstNameLabel,
	gapEducationLabel,
	highestLevelEducationLabel,
	labelOnNavigation,
	lastNameLabel,
	mobileNumberLabel,
	selectDateLabel,
	selectTimeLabel,
	targetCountryLabel,
	targetIntakeLabel,
	testNameLabel,
	testScoreLabel,
	testTakenLabel,
	title,
	highlightTitle,
	titleFontSize,
	analyticsProps,
	styled,
	...props
}) {
	const profileData = useSelector((state) => state.profile.data);
	const targetIntakes = useSelector((state) =>
		getTargetIntakes(
			get(
				state,
				'cmsGlobalData.data.globalFormConfig.schemaConstants.target_intake.enum',
				[]
			)
		)
	);

	const [buttonDisable, setButtonDisable] = useState(true);
	const [isScroll, setIsScroll] = useState(false);
	const [highestLevelOfEducation, setSelectedHighestLevelOfEducation] =
		React.useState('');
	const [selectedEducationGap, setSelectedEducationGap] = React.useState('');
	const [selectedCountry, setSelectedCountry] = React.useState('');
	const [selectedIntake, setSelectedIntake] = React.useState('');
	const [testNameVal, setTestNameVal] = React.useState();
	const [educationScore, setEducationScore] = React.useState('');
	const [educationBacklogs, setEducationBacklogs] = React.useState('');
	const [examTaken, setExamTaken] = React.useState();
	const [testScore, setTestScore] = useState();
	// step 3
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [city, setCity] = useState('');
	const [email, setEmail] = useState('');
	// all payload
	const [initialPayload, setInitialPayload] = useState({});
	//error
	const [error, setError] = useState({
		educationScore: '',
		testScore: '',
		educationBacklogs: '',
		email: '',
		firstName: '',
		lastName: '',
		city: '',
	});
	const [step, setStep] = useState(1);
	const stepData = Step(step, {
		cardList,
		cityLabel,
		educationBacklogsLabel,
		educationScoreLabel,
		emailLabel,
		firstNameLabel,
		gapEducationLabel,
		highestLevelEducationLabel,
		labelOnNavigation,
		lastNameLabel,
		mobileNumberLabel,
		selectDateLabel,
		selectTimeLabel,
		targetCountryLabel,
		targetIntakeLabel,
		testNameLabel,
		testScoreLabel,
		testTakenLabel,
	});
	const profileImage = stepData.cardList.image.url;
	const mobileImage =
		stepData &&
		stepData.cardList &&
		stepData.cardList.mobileImage &&
		stepData.cardList.mobileImage.data &&
		stepData.cardList.mobileImage.data.attributes;
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const isDesktop = useSelector((state) => state.common.isDesktop);
	const stateData = useSelector((state) => state.global.data);

	const [cities, setCities] = useState(get(stateData, 'cities') || []);

	const trackEvent = (eventName, eventProperties) => {
		eventActions.trackEvent(eventName, {
			...analyticsProps,
			section: 'LIVE_COUNSELLING_PLUG_SECTION',
			...eventProperties,
		});
	};

	const educationBacklogsChange = (event) => {
		const inputTestScore = event.target.value;
		setEducationBacklogs(inputTestScore);
		if (
			!inputTestScore.match(/(\d+(?:\.\d+)?)/) ||
			inputTestScore < 0 ||
			inputTestScore > 100
		) {
			setError({
				...error,
				educationBacklogs: errorMessages.educationBacklogs,
			});
		} else {
			setError({ ...error, educationBacklogs: '' });
		}
	};

	const handleCountryClicked = (value) => {
		setExamTaken('');
		setTestNameVal('');
		setTestScore('');
		setButtonDisable(true);
		setSelectedCountry(value === selectedCountry ? '' : value);
	};

	const handleIntakeClicked = (value) => {
		setExamTaken('');
		setTestNameVal('');
		setTestScore('');
		setButtonDisable(true);
		setSelectedIntake(value === selectedIntake ? '' : value);
	};

	const handleExamTakenClick = (value) => {
		setTestNameVal('');
		setTestScore('');
		setButtonDisable(true);
		setExamTaken(value === examTaken ? '' : value);
		if (value == 'No' && (examTaken == '' || examTaken == 'Yes')) {
			setTestNameVal('');
			setTestScore('');
			setButtonDisable(false);
		}
	};

	const handleTestNameSelect = (value) => {
		setTestNameVal(value);
		setTestScore('');
		setButtonDisable(true);
	};

	const levelOfEducationSelect = (value) => {
		setSelectedHighestLevelOfEducation(value);
	};
	const handleEducationGapClick = (value) => {
		setSelectedEducationGap(value);
	};

	const handleTestScoreChange = (value) => {
		setButtonDisable(true);
		const inputTestScore = value;
		if (examTaken === 'No') {
			return;
		}
		setTestScore(inputTestScore);
		setError({
			...error,
			testScore: '',
		});
		const errorObj = {
			TOEFL: {
				minimum: 0,
				maximum: 120,
			},
			IELTS: {
				minimum: 1,
				maximum: 9,
			},
			PTE: {
				minimum: 0,
				maximum: 90,
			},
			GRE: {
				minimum: 260,
				maximum: 340,
			},
			GMAT: {
				minimum: 200,
				maximum: 800,
			},
			SAT: {
				minimum: 400,
				maximum: 1600,
			},
			DUOLINGO: {
				minimum: 10,
				maximum: 160,
			},
		};

		setError({
			...error,
			testScore: '',
		});

		if (
			testNameVal === 'TOEFL' &&
			inputTestScore.match(/^(?:\d{1,2}(?:\.\d{1,2})?|120(?:\.0)?)$/)
		) {
			setButtonDisable(false);
		} else if (
			testNameVal === 'IELTS' &&
			inputTestScore.match(/^(?:[1-9](?:\.\d{1,2})?|9(?:\.0)?)$/)
		) {
			setButtonDisable(false);
		} else if (
			testNameVal === 'PTE' &&
			inputTestScore.match(/^(?:[0-9]|[1-8][0-9]|90)(?:\.\d{1,2})?$/)
		) {
			setButtonDisable(false);
		} else if (
			testNameVal === 'GRE' &&
			inputTestScore.match(
				/^(26[0-9]|2[7-9][0-9]|3[0-3][0-9]|340)(?:\.\d{1,2})?$/
			)
		) {
			setButtonDisable(false);
		} else if (
			testNameVal === 'GMAT' &&
			inputTestScore.match(/^(?:200|(?:(?:[2-7]\d{2})|800))(?:\.\d{1,2})?$/)
		) {
			setButtonDisable(false);
		} else if (
			testNameVal === 'SAT' &&
			inputTestScore.match(/^(?:[4-9]\d{2}|1[0-5]\d{2}|1600)(?:\.\d{1,2})?$/)
		) {
			setButtonDisable(false);
		} else if (
			testNameVal === 'DUOLINGO' &&
			inputTestScore.match(/^(1[0-5]\d|160|10|\d{2})$/)
		) {
			setButtonDisable(false);
		} else {
			setError({
				...error,
				testScore: `The ${testNameVal} score should be >= ${errorObj[testNameVal].minimum} and <= ${errorObj[testNameVal].maximum}`,
			});
		}
	};

	const educationScoreSelect = (event) => {
		const inputTestScore = event.target.value;
		setEducationScore(inputTestScore);

		if (
			!inputTestScore.match(/(\d+(?:\.\d+)?)/) ||
			inputTestScore < 0 ||
			inputTestScore > 100
		) {
			setError({
				...error,
				educationScore: errorMessages.educationScore,
			});
		} else {
			setError({ ...error, educationScore: '' });
		}
	};

	const handleEmailChange = (event) => {
		const newEmail = event.target.value;
		setEmail(newEmail);
		if (!newEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || newEmail < 0) {
			setError({
				...error,
				email: errorMessages.email,
			});
		} else {
			setError({
				...error,
				email: '',
			});
		}
	};

	const firstNameChange = (event) => {
		const newFirstName = event.target.value;
		setFirstName(newFirstName);
		if (newFirstName <= 0) {
			setError({
				...error,
				firstName: errorMessages.firstName,
			});
		} else {
			setError({
				...error,
				firstName: '',
			});
		}
	};

	const lastNameChange = (event) => {
		const newLastName = event.target.value;
		setLastName(newLastName);
		if (newLastName <= 0) {
			setError({
				...error,
				lastName: errorMessages.lastName,
			});
		} else {
			setError({
				...error,
				lastName: '',
			});
		}
	};

	const citySelect = (value) => {
		const newCity = value;
		setCity(newCity);
		if (newCity <= 0) {
			setError({
				...error,
				city: errorMessages.city,
			});
		} else {
			setError({
				...error,
				city: '',
			});
		}
	};

	const onModalClose = () => {
		setSelectedEducationGap('');
		setSelectedCountry('');
		setFirstName('');
		setLastName('');
		setTestScore('');
		setExamTaken('');
		setCity('');
		setEmail('');
		setTestNameVal('');
		setEducationBacklogs('');
		setEducationScore('');
		setSelectedIntake('');
		setInitialPayload({});
		setSelectedHighestLevelOfEducation('');
		setError({
			educationScore: '',
			testScore: '',
			educationBacklogs: '',
			email: '',
			firstName: '',
			lastName: '',
			city: '',
		});
		setStep(1);
	};

	React.useEffect(() => {
		setSelectedCountry(
			get(profileData, 'desiredProfile.interest_countries[0]') || ''
		);
		setFirstName(get(profileData, 'firstName') || '');
		setLastName(get(profileData, 'lastName') || '');
		setTestNameVal(get(profileData, 'testScores[0].name') || '');
		setTestScore(get(profileData, 'testScores[0].scores.overall') || null);
		setCity(get(profileData, 'city') || '');
		setEmail(get(profileData, 'email') || '');
		setExamTaken(get(profileData, 'is_tests_opted') || '');
		setEducationScore(
			getEducationScore(get(profileData, 'educationHistory')) || ''
		);
		setEducationBacklogs(
			get(profileData, 'educationHistory.backlogs_count') &&
				profileData.educationHistory.backlogs_count >= 0
				? profileData.educationHistory.backlogs_count
				: ''
		);
		setSelectedHighestLevelOfEducation(
			get(profileData, 'educationHistory.highest_education_level') || ''
		);
		if (get(profileData, 'educationHistory.education_gap')) {
			const educationGap = profileData.educationHistory.education_gap;
			setSelectedEducationGap(
				educationGap > 10 || educationGap === '10+' ? '10+' : educationGap
			);
		}
		if (
			get(profileData, 'desiredProfile.intake_month') &&
			get(profileData, 'desiredProfile.intake_year') &&
			targetIntakes.length
		) {
			let intake = `${profileData.desiredProfile.intake_month} ${profileData.desiredProfile.intake_year}`;
			intake =
				profileData.desiredProfile.intake_year <
					parseInt(targetIntakes[0].split(' ')[1]) ||
				!targetIntakes.find((item) => item === intake)
					? targetIntakes[0]
					: intake;
			setSelectedIntake(intake);
		} else setSelectedIntake('');
	}, [profileData]);

	React.useEffect(() => {
		const initialData = {
			targetCountry: selectedCountry,
			aimmingYear: selectedIntake,
			givenTest: examTaken,
			testName: testNameVal,
			testScore,
			highestLevelOfEducation,
			educationScore: parseInt(educationScore),
			educationBacklogs,
			educationGap: selectedEducationGap,
			firstName,
			lastName,
			email,
			city,
		};
		setInitialPayload(initialData);
	}, [
		selectedCountry,
		selectedIntake,
		examTaken,
		testNameVal,
		testScore,
		highestLevelOfEducation,
		educationScore,
		educationBacklogs,
		selectedEducationGap,
		firstName,
		lastName,
		city,
		email,
	]);

	React.useEffect(() => {
		if (
			examTaken == 'No' ||
			(get(profileData, 'is_tests_opted') == 'Yes' &&
				get(profileData, 'testScores[0].name') &&
				get(profileData, 'testScores[0].scores.overall'))
		) {
			setButtonDisable(false);
		} else {
			setButtonDisable(true);
		}
	}, [profileData, examTaken]);

	if (isScroll) {
		scroller.scrollTo(textVal.scrollId, { duration: 0, offset: -85 });
		setIsScroll(false);
	}

	return (
		<>
			{!isMobile && isDesktop && (title || highlightTitle) ? (
				<Box className={classes.titleWrap}>
					<TitleSection
						title={title}
						highlightTitle={highlightTitle}
						fontSize={titleFontSize || titleSizes.medium}
						highlightTitleAfter={true}
					/>
				</Box>
			) : null}

			<Box
				className={cx(
					classes.liveCounsellingSection,
					styled ? classes.styled : ''
				)}
				id={textVal.scrollId}
			>
				{isMobile || !isDesktop ? (
					step !== 4 ? (
						<Box className={classes.mobileTitleSection}>
							{mobileImage ? (
								<Box className={classes.mobileProfilImg}>
									<ImageComponent
										className={classes.profilePic}
										src={mobileImage.url}
										alt={mobileImage.alternativeText}
										width={
											isMobile
												? '60px'
												: isDesktop
												? stepData.cardList.height
												: '90px'
										}
										height={
											isMobile
												? '70px'
												: isDesktop
												? stepData.cardList.height
												: '90px'
										}
									/>
								</Box>
							) : null}
							<Box className={classes.mobileTitle}>
								<Typography
									component="h2"
									mt={!isDesktop && !isMobile ? 10 : 0}
								>
									{stepData.cardList.highlightTitle && (
										<Typography
											variant={isMobile ? 'h6' : isDesktop ? 'h3' : 'h4'}
											component="span"
											color="primary"
											fontWeight="fontWeightSemiBold"
											fontSize={isMobile ? '18px' : '24px'}
										>
											{stepData.cardList.highlightTitle}&nbsp;
										</Typography>
									)}
									{stepData.cardList.title && (
										<Typography
											variant={isMobile ? 'h6' : isDesktop ? 'h3' : 'h4'}
											component="span"
											fontWeight="fontWeightSemiBold"
											fontSize={isMobile ? '18px' : '24px'}
										>
											{stepData.cardList.title}
										</Typography>
									)}
								</Typography>
							</Box>
						</Box>
					) : null
				) : (
					<Box className={classes.titleSection}>
						<Box className={classes.titleInnerSection}></Box>
						<Box className={classes.profileContainer}>
							{stepData &&
							stepData.cardList &&
							stepData.cardList.image &&
							stepData.cardList.image.url ? (
								<ImageComponent
									className={classes.profilePic}
									src={profileImage}
									alt={stepData.cardList.image.alternativeText}
									width={isMobile ? '52px' : stepData.cardList.width}
									height={isMobile ? '54px' : stepData.cardList.height}
								/>
							) : null}

							<Typography component="h2" mt={isDesktop ? 0 : 0}>
								{stepData.cardList.highlightTitle && (
									<Typography
										variant={isMobile ? 'h6' : isDesktop ? 'h3' : 'h4'}
										component="span"
										color="primary"
										fontWeight="fontWeightSemiBold"
										fontSize={isMobile ? '18px' : '24px'}
									>
										{stepData.cardList.highlightTitle}&nbsp;
									</Typography>
								)}
								{stepData.cardList.title && (
									<Typography
										variant={isMobile ? 'h6' : isDesktop ? 'h3' : 'h4'}
										component="span"
										fontWeight="fontWeightSemiBold"
										fontSize={isMobile ? '18px' : '24px'}
									>
										{stepData.cardList.title}
									</Typography>
								)}
							</Typography>
						</Box>
					</Box>
				)}
				<Box className={classes.filterInnerSection}>
					{step === 1 ? (
						<StepOne
							targetCountryLabel={stepData.targetCountryLabel}
							selectedCountry={selectedCountry}
							handleCountryClicked={handleCountryClicked}
							textValues={textValues}
							targetIntakeLabel={stepData.targetIntakeLabel}
							selectedIntake={selectedIntake}
							handleIntakeClicked={handleIntakeClicked}
							examTaken={examTaken}
							testTakenLabel={stepData.testTakenLabel}
							handleExamTakenClick={handleExamTakenClick}
							testNameLabel={stepData.testNameLabel}
							testScoreLabel={stepData.testScoreLabel}
							testName={testName}
							testNameVal={testNameVal}
							testScore={testScore}
							handleTestNameSelect={handleTestNameSelect}
							error={error}
							handleTestScoreChange={handleTestScoreChange}
							targetIntakes={targetIntakes}
							buttonDisable={buttonDisable}
							setStep={setStep}
							setIsScroll={setIsScroll}
							trackEvent={trackEvent}
						/>
					) : null}
					{step === 2 ? (
						<StepTwo
							highestLevelEducationLabel={stepData.highestLevelEducationLabel}
							educationScoreLabel={stepData.educationScoreLabel}
							educationBacklogsLabel={stepData.educationBacklogsLabel}
							gapEducationLabel={stepData.gapEducationLabel}
							handleEducationGapClick={handleEducationGapClick}
							selectedEducationGap={selectedEducationGap}
							setStep={setStep}
							levelOfEducationSelect={levelOfEducationSelect}
							HighestDegree={stateData.highest_degree}
							error={error}
							educationScore={educationScore}
							levelOfEducation={highestLevelOfEducation}
							educationScoreSelect={educationScoreSelect}
							educationBacklogsChange={educationBacklogsChange}
							educationBacklogs={educationBacklogs}
							setIsScroll={setIsScroll}
							trackEvent={trackEvent}
						/>
					) : null}
					{step === 3 ? (
						<StepThree
							setStep={setStep}
							firstNameLabel={stepData.firstNameLabel}
							emailLabel={stepData.emailLabel}
							cityLabel={stepData.cityLabel}
							mobileNumberLabel={stepData.mobileNumberLabel}
							firstName={firstName}
							lastName={lastName}
							lastNameLabel={stepData.lastNameLabel}
							city={city}
							email={email}
							cities={cities}
							setCities={setCities}
							error={error}
							firstNameChange={firstNameChange}
							lastNameChange={lastNameChange}
							handleEmailChange={handleEmailChange}
							citySelect={citySelect}
							initialPayload={initialPayload}
							setError={setError}
							mobileNumber={profileData ? profileData.mobile : ''}
							setIsScroll={setIsScroll}
							trackEvent={trackEvent}
							constants={props.constants}
							formPageConstants={props.formPageConstants}
						/>
					) : null}
					{step === 4 ? (
						<StepFour
							iconImage={
								get(stepData, 'cardList.iconImage.data.attributes') || {}
							}
							formTitle={stepData.cardList.formTitle}
							formDescription={stepData.cardList.formDescription}
							highlightTitle={stepData.cardList.highlightTitle}
							selectDateLabel={stepData.selectDateLabel}
							selectTimeLabel={stepData.selectTimeLabel}
							height={stepData.height}
							onModalClose={onModalClose}
							setIsScroll={setIsScroll}
							trackEvent={trackEvent}
						/>
					) : null}
				</Box>
			</Box>
		</>
	);
}
LiveCounsellingSection.propTypes = {
	cardList: PropTypes.array,
	cityLabel: PropTypes.string,
	educationBacklogsLabel: PropTypes.string,
	educationScoreLabel: PropTypes.string,
	emailLabel: PropTypes.string,
	firstNameLabel: PropTypes.string,
	gapEducationLabel: PropTypes.string,
	title: PropTypes.string,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	highestLevelEducationLabel: PropTypes.string,
	labelOnNavigation: PropTypes.any,
	lastNameLabel: PropTypes.string,
	mobileNumberLabel: PropTypes.string,
	selectDateLabel: PropTypes.string,
	selectTimeLabel: PropTypes.string,
	targetCountryLabel: PropTypes.string,
	targetIntakeLabel: PropTypes.string,
	testNameLabel: PropTypes.string,
	testScoreLabel: PropTypes.string,
	testTakenLabel: PropTypes.string,
	styled: PropTypes.bool,
	analyticsProps: PropTypes.object,
};

LiveCounsellingSection.defaultProps = {
	analyticsProps: {},
	styled: false,
};
